.square-wrapper {
  position: relative;

  .magnifier-wrapper {
    position: absolute;
    width: 32px;
    height: 32px;
    top: 0;
    right: 0;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    cursor: pointer;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 5px;
    overflow: hidden;
  }
}
/* body {
  margin: 0
}

.App {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: #85a8c5;
} */

.carousel {
  /* overflow-x: hidden; */
  /* width: 700px; */
  width: 100%;
  /* display: flex; */
  flex-direction: column;
  /* justify-content: center; */
}

.inner {
  white-space: nowrap;
  transition: transform 0.3s;
  /* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05); */
}

.carousel-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  /* height: 490px; */
  /* background-color: #fff; */
}

.carousel-item-title {
  color: #000000;
  font-size: 36px;
}

.carousel-item-subtitle {
  color: #000000;
  font-size: 36px;
  font-weight: bold;
}

.carousel-item-text {
  /* font-size: 18px; */
  margin: 80px 0 80px 0;
  /* padding: 40px 20px 40px 20px; */
  padding-left: 40px;
  padding-right: 40px;
  text-align: center;
  white-space: normal;
  color: #5D6476;
}

/* final stage */

.carousel-buttons {
  display: flex;
  justify-content: space-evenly;
}

.button-arrow {
  background: none;
  border: none;
  cursor: pointer;
  margin-top: 20px;
}

.indicators {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
}

.indicator-buttons {
  border: none;
  cursor: pointer;
}

.indicators > button {
  margin: 5px;
  background: none;
}

.indicator-symbol {
  color: #26343f;
}

.indicator-symbol-active {
  color: #ffffff;
}

* {
  box-sizing: border-box;
}

body {
  background-color: #f1f6fb; 
  font-family: 'Source Sans 3', sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #5D6476;
  /* overscroll-behavior-y: none; */
}

button {
  font-family: 'Source Sans 3', sans-serif;
}

input {
  font-family: 'Source Sans 3', sans-serif;
}

p {
  margin: 0;
  padding: 0;
}

.grid {
  width: 420px;
  height: 420px;
  padding: 5px;
  background-color: darkmagenta;
  display: flex;                       /* establish flex container */
  flex-wrap: wrap;                     /* enable flex items to wrap */
  /* justify-content: space-around; */
}

.cell {
  /* flex: 0 0 32%;                       don't grow, don't shrink, width */
  width: 130px;
  height: 130px;
  /* margin-bottom: 5px; */
  border-radius: 10px;
  margin: 5px;
  background-color: #C2DCBB;
}

/* .cell:nth-child(3n) {
  background-color: #F00;
} */

label {
  color: #5D6476;
}

.pt-10 {
  padding-top: 10px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-100 {
  padding-top: 100px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-100 {
  padding-bottom: 100px;
}

.mt-0 {
  margin-top: 0px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-100 {
  margin-top: 100px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-100 {
  margin-bottom: 100px;
}

.text-center {
  text-align: center;
}

.text-start {
  text-align: left;
}

.text-end {
  text-align: right;
}

.text-bold {
  font-weight: 700;
}

.text-primary {
  color: rgb(71, 130, 250) !important;
}

.text-success {
  color: rgb(71, 130, 250) !important;
}

.text-danger {
  color: #FB4747 !important;
}

.d-flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.align-items-start {
  align-items: start;
}

.align-itmes-end {
  align-items: end;
}

.font-bold {
  font-weight: 700;
}

label.required:after {
  content: " *";
  color: #ff0000;
}

.modal-content {
  word-wrap: break-word;
}
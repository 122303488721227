.modal-container {
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(240, 246, 252, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  .modal-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 40px;
    background-color: #F0F6FC;
    border: 1px solid #BBD1E7;
    border-radius: 50px;
    box-shadow: 0px 4px 25px rgba(64, 76, 85, 0.15);
    text-align: center;

    .button {
      font-size: 18px;
      font-weight: 600;
      background-color: #4782FA;
      color: #FFFFFF;
      cursor: pointer;
      height: 59px;
      border: none;
      border-radius: 29px;
    }
  }
}
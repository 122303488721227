.leaderboard-container {
    position: fixed;
    padding: 0;
    margin: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #F0F6FC;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #171D2E;
    font-size: 36px;
    cursor: pointer;
    z-index: 2;

    .leaderboard-wrapper {
      table {
        font-size: 15px;
        width: 100%;

        tr {
          td:last-child {
            font-size: 14px;
            font-weight: 700;
          }
        }
      }
    }
}
.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  top: 0;
  z-index: 10;
  font-size: 30px;
  font-weight: 700;
}

.links-wrapper {
  display: flex;
  justify-content: space-between;

  a {

    button {
      background-color: #F0F6FC;
      color: #5D6476;
      font-size: 18px;
      font-weight: 600;
      cursor: pointer;
      width: 100%;
      height: 59px;
      border: 2px solid #5D6476;
      border-radius: 30px;
      overflow: hidden;
      padding: 0;
    }
  }
}
.reset-password-container {
    position: relative;
    height: 100vh;
    width: 100%;
    display: flex;
    // background-color: 'red';
    justify-content: center;
    align-items: center;
    margin: auto;

    .reset-password-wrapper {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 90%;

        .email-input {
            text-align: center;
            border: 0;
            border-radius: 20px;
            padding: 10px;
            font-size: 17px;
            width: 100%;
        }
    }
}
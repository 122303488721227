.navbar-wrapper {
  width: 100%;

  .language-selector-wrapper {
    select {
      flex: 1;
      border: none;
      outline: none;
      font-size: 18px;
      font-weight: 600;
      color: rgb(93, 100, 118);
      border-radius: 20px;
    }
  }

  .off-screen-wrapper {
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 18px;
    transition: .3s ease;
    z-index: 999;

    &.active {
      right: 0;
    }

    .off-screen-menu {
      width: 100%;
      height: 100vh;
      max-width: 420px;
      padding: 10px 24px;
      background-color: #C2DCBB;
      opacity: 1;
      text-align: left;

      .close-wrapper {
        text-align: right;
      }

      ul {
        padding-left: 0;
      }

      .off-screen-item {
        list-style: none;
        margin-bottom: 10px;
        background-color: white;
        border-radius: 20px;
        padding: 10px 20px;

        a {
          font-size: 18px;
          font-weight: 600;
          color: rgb(93, 100, 118);
          text-decoration: none;
          
          &:hover {
            opacity: 0.7;
          }
        }

        &.language-selector-wrapper {
          display: flex;
          align-items: center;

          label {
            font-size: 18px;
            font-weight: 600;
            color: rgb(93, 100, 118);
            margin-right: 20px;
          }

          select {
            flex: 1;
            border: none;
            outline: none;
            font-size: 18px;
            font-weight: 600;
            color: rgb(93, 100, 118);
            border-radius: 20px;
          }
        }
      }
    }
  }
}
.confirm-invite-container {
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #F0F6FC;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // height: 932,
    // height: 100vh;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;

    .form-header {
      color: #171D2E;
      font-size: 36px;
      font-weight: bold;
    }

    .form-input {
      background-color: #FFFFFF; 
      width: 100%;
      box-sizing: border-box;
      height: 54px;
      border-radius: 27px;
      border: none;
      text-align: left;
      font-size: 18px;
      color: #5D6476;
      padding: 0px 22px 0px 22px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px 0px 10px 0px;
    }

    .form-link {
      text-decoration: none;
      font-weight: bold;
      color: #4782FA;
    }

    .form-secondary-link {
      text-decoration: underline;
      font-weight: bold;
      color: rgba(93, 100, 118, 0.4);
    }
  }
}
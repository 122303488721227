.scoreboard-container {
  width: 100%;
  max-width: 420px;
  margin: auto;

  .scoreboard-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 60px 5%;

    p {
      font-size: 20px;
      margin-bottom : 10px;
      text-align: center;

      &.score-number {
        font-size: 40px;
        font-weight: 700;
      }
    }
  }
}